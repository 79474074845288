<template>
  <p class="px-3 py-2 rounded-md bg-red-100 text-red-700">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'Paragraph',
};
</script>
