<template>
  <div class="w-full h-full max-w-3xl container px-3">
    <router-view />
  </div>
</template>

<script>
export default {
  setup() {
  },
};
</script>
